import React from 'react';
import Test2 from '../../components/Test1/test2';
import queryString from 'query-string';

const GiftPage = ({ match, location }) => {
    const query = queryString.parse(location.search);
    return (
        <React.Fragment >
            <Test2 p_id={match.params.p_id} giftKey={query.giftKey} />
        </React.Fragment>
    );
};

export default GiftPage;