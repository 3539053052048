import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
// import Test from './components/Test1/test';
import {
   Package, Gift, Tou
} from './pages'; //각 페이지들 리스트


class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Route path="/package/:p_id" component={Package}/>
          <Route path="/gift/package/:p_id" component={Gift}/>
          <Route path="/tou" component={Tou}/>
        </div>
      </BrowserRouter>
    )
  }
}

export default App;
