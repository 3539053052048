import React from 'react';
import Test from '../../components/Test1/test';

const PackagePage = ({ match, location }) => {
    return (
        <React.Fragment >
            <Test p_id={match.params.p_id}/>
        </React.Fragment>
    );
};

export default PackagePage;