import React from 'react';
import Tou from '../../components/Test1/tou';

const PackagePage = ({ match, location }) => {
    return (
        <React.Fragment >
            <Tou />
        </React.Fragment>
    );
};

export default PackagePage;