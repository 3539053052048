import React from 'react';
import './test.scss';

import stipop_logo from './static/media/stipop-logo.png';
import stipop_logo2 from './static/media/stipop-logo@2x.png';
import stipop_logo3 from './static/media/stipop-logo@3x.png';

import giftIcon from './static/media/gift.png';



class Test extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isClicked: false,
            p_id: this.props.p_id,
            giftKey: this.props.giftKey,
            viewClassname: 'Rectangle-View',
            goWebClassname: 'Rectangle-GoWeb',
            downloadClassname: 'Rectangle-Download',
            textGoWeb: 'Pink-Text',
            textDownload: 'Pink-Text',
            packageInfo: null
        };
    }

    componentDidMount() {
        // console.log(this.props.giftKey)
        this._getPackageInfo();
    }

    _getPackageInfo = async () => {

        const { p_id, giftKey } = this.state;

        const response = await fetch("/webApp/package/" + p_id + "/" + giftKey, {
           headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
               'Access-Control-Allow-Origin': '*'
           }
           ,method: 'get'
        });

        const responseJson = await response.json();

        this.setState({
            packageInfo : responseJson.packageInfo[0]
        })
    }

    _platformCheck = () => {
        const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

        if (varUA.match('android') != null) { 
            window.location.href = 'market://details?id=io.stipop.sticker_platform'
        } else if (varUA.indexOf("iphone")>-1||varUA.indexOf("ipad")>-1||varUA.indexOf("ipod")>-1) { 
            window.location.href = 'itms-apps://itunes.apple.com/us/app/apple-store/id1288492157?mt=8'
        } else {
            window.location.href = 'https://apps.apple.com/app/id1288492157'
        }
    }

    _deepLink = () => {
        const { p_id } = this.state;
        const varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

        if (varUA.match('android') != null) { 
            //window.location.href = 'Intent://feed/'+ p_id +'#Intent;scheme=stipop;action=.MainActivity;category=android.intent.category.BROWSABLE;package=io.stipop.sticker_platform;end'
            window.location.href = 'intent://feed/'+p_id+'/'+this.props.giftKey+'#Intent;action=android.intent.action.VIEW;scheme=stipop;package=io.stipop.sticker_platform;end';
            //window.location.href = 'intent://#Intent;scheme=stipop;package=io.stipop.sticker_platform;end'
        } else if (varUA.indexOf("iphone")>-1||varUA.indexOf("ipad")>-1||varUA.indexOf("ipod")>-1) { 
            window.location.href = 'stipop://feed/' + p_id + '/' + this.props.giftKey;
        } 
    }

    renderViewButton() {
        const { packageInfo } = this.state;

        if (packageInfo && packageInfo.giftYN === 'N') {
            return (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={this._deepLink} >
                    <div
                        className={this.state.viewClassname}
                        style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        onMouseDown={() => this.setState({viewClassname: 'Rectangle-View-Pressed'})}
                        onMouseUp={() => this.setState({viewClassname: 'Rectangle-View'})}
                    >
                        <p className="See-more-at-Stipop">Get a gift</p>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}  >
                    <div
                        className={this.state.viewClassname}
                        style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        onMouseDown={() => this.setState({viewClassname: 'Rectangle-View-Pressed'})}
                        onMouseUp={() => this.setState({viewClassname: 'Rectangle-View'})}
                    >
                        <p className="See-more-at-Stipop">Gift expired</p>
                    </div>
                </div>
            );
        }

    }

    renderGoWebButton() {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                <div
                    className={this.state.goWebClassname}
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    onMouseDown={() => this.setState({goWebClassname: 'Rectangle-Pressed', textGoWeb: 'Text-Pressed'})}
                    onMouseUp={() => this.setState({goWebClassname: 'Rectangle-GoWeb', textGoWeb: 'Pink-Text'})}
                >
            
                    <p className={this.state.textGoWeb}>Go to the Stipop store</p>
                </div>
            </div>
        );
    }

    renderDownloadButton() {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={this._platformCheck}>
                <div
                    className={this.state.downloadClassname}
                    style={{ marginTop: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    onMouseDown={() => this.setState({downloadClassname: 'Rectangle-Pressed', textDownload: 'Text-Pressed'})}
                    onMouseUp={() => this.setState({downloadClassname: 'Rectangle-Download', textDownload: 'Pink-Text'})}
                >
            
                    <p className={this.state.textDownload}>Download Stipop App</p>
                </div>
            </div>
        );
    }


    render() {

        const { packageInfo } = this.state;

        return (
            <div>
                <div style={{ marginTop: '3%', marginLeft: '28px' }}>
                    <img src={stipop_logo}
                        srcSet={`${stipop_logo2} 2x, ${stipop_logo3} 3x`}
                        className="stipop_logo" />
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className="To-continue-Please">
                        The gift has arrived! <br/> You can use it on your messenger!
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {
                        packageInfo ?
                        <>
                        <img src={packageInfo.p_img} style={{ marginTop: '15%', align: 'center' }} className="sticker_image" />
                        <img src={giftIcon} style={{ width:77, height:92, position:"absolute", right:"25%", top:"30%" }}  />
                        </>
                        :
                        <div style={{ marginTop: '1%', align: 'center' }} className="non_sticker_image"/>
                    }
                    
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{ marginTop: '0.2%', textAlign: 'center' }} className="name">
                        {
                            packageInfo && packageInfo.p_name
                        }
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0.9%'}} className="Rectangle">
                        {
                            packageInfo && <p className="Artist">©{packageInfo.u_name}</p>
                        }
                    </div>
                </div>
                {this.renderViewButton()}
                {/* {this.renderGoWebButton()} */}
            </div>
        );
    }
}

export default Test;
