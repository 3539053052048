import React from 'react';
import './test.scss';

import stipop_logo from './static/media/stipop-logo.png';
import stipop_logo2 from './static/media/stipop-logo@2x.png';
import stipop_logo3 from './static/media/stipop-logo@3x.png';

class Tou extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isClicked: false,
            p_id: this.props.p_id,
            viewClassname: 'Rectangle-View',
            goWebClassname: 'Rectangle-GoWeb',
            downloadClassname: 'Rectangle-Download',
            textGoWeb: 'Pink-Text',
            textDownload: 'Pink-Text',
            packageInfo: null
        };
    }


    render() {

        return (
            <div>
                <div style={{}}>
                    <img src={stipop_logo}
                        srcSet={`${stipop_logo2} 2x, ${stipop_logo3} 3x`}
                        className="stipop_logo" />
                </div>
                <p><a href="https://img.stipop.io/pdf/Stipop+Terms+of+Use_201116-converted.pdf"><strong style={{fontSize:20}}>Terms of Use</strong></a></p>
                <p>Date of proclamation: November 16, 2020</p><br/>
                <p><strong style={{fontSize:20}}>Welcome to Stipop!</strong></p>
                <p>Thank you for using our products and services (“Services”) – Stipop Web located at www.stipop.io and stipop iOS/Android mobile applications. Our Services is a platform where anyone, individual or non-individual, professional or non-professional creatives around the globe, can freely post and/or create content through the App (“User Generated Content”) and/or upload their artworks in form of stickers for messengers and use them in iMessage, WhatsApp and Gboard. The Services are provided by Stipop Inc. (“Stipop”, “us”, “we” or “our”), located in Fl.1, 3340 Ocean Park Boulevard, Santa Monica, CA 90405, United States. Stipop Terms of Use (“Terms”) is subjected to all users and visitors of our Services (“You”).</p>
                <p>By using or accessing our Services, you are agreeing to these Terms. Please read them carefully.</p>
                <p>You hereby certify that You are at least 13 years of age. In jurisdictions where 13 is not the age of consent to contract, You represent and warrant that You have all necessary authorizations and permissions from an appropriate legal guardian. You also certify that You are otherwise legally permitted to use our Services.</p>
                <p><br/><strong style={{fontSize:20}}>Using our Services</strong></p>
                <p>You can use all stickers provided within the App by following means: watching ads or purchasing paid stickers through in-app purchase. Downloaded stickers can be used in iMessage, Gboard and WhatsApp, seamlessly. In this way you can use unlimited number of stickers with ease with choosing from purchasing model that suits you best.</p>
                <p>You must follow any policies made available to you within the Services. We may suspend or stop providing our Services if you do not comply with our Terms or policies.</p>
                <p>First, do not misuse our Services. Any comments or contents that intrude or violate any personal/human rights are forbidden and will be removed without permission if found. We will not tolerate any racism, gender inequality, plagiarism or propaganda in our Services. Do not interfere with our Services by violating Terms provided or try to access them using methods other than the interface and instructions provided. You may use our Services only as permitted by law.</p>
                <p>Second, creating an account in Stipop and using our Services does not give you ownership of any intellectual property rights in our Services or the content you access or download. You may only use the content as instructed, which is to send them on iMessage, WhatsApp and Gboard and share posts on Stipop App or other notified social networks. If downloaded contents (stickers & posts) are used otherwise, without its owner’s (Contributor’s) permission, you may be suspended from our Services and face legal issues. </p>
                <p>Third, our Services may provide content that is not Stipop’s. This content is the sole responsibility of the entity (User or Contributor) that makes it available. We may review content to determine whether it is illegal or violates our policies, and we may remove or refuse to display content that we reasonably believe violates our policies or the law. But that does not necessarily mean that we review content, so please don’t assume that we do. </p>
                <p>In connection with your use of the Services, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications by informing us through Help Center.</p>
                <p><br/><strong style={{fontSize:20}}>Purchases</strong></p>
                <p>When you purchase a paid sticker package (each, a “Transaction”), all purchases go through the in-app purchase process made available by Google Play Store and Apple App Store. The amounts due and payable by you for a Transaction through the Services will be presented to you before you place your order. If you choose to initiate a Transaction via the Services, you authorize us to provide your Payment Information to third party service providers so we can complete your Transaction and agree (a) to pay the applicable fees and any taxes; (b) that stipop may charge your credit card or third party payment processing account, including, but not limited to, your account with the app store or distribution platform (like the Apple App Store, Google Play or the Amazon Appstore) where the App is made available (each, an “App Provider”), for verification, pre-authorization and payment purposes; and (c) to bear any additional charges that your App Provider, bank or other financial service provider may levy on you as well as any taxes or fees that may apply to your order. You’ll receive a confirmation email after we confirm the payment for your order. Your order is not binding on stipop until accepted and confirmed by stipop. All payments made are non-refundable and non-transferable except as expressly provided in these Terms.</p>
                <p>If you have any concerns or objections regarding charges, you agree to raise them with us first and you agree not to cancel or reject any credit card or third party payment processing charges unless you have made a reasonable attempt at resolving the matter directly with stipop.</p>
                <p>Stipop reserves the right to not process or to cancel your order in certain circumstances, for example, if your credit card is declined, if we suspect the request or order is fraudulent, or in other circumstances stipop deems appropriate in its sole discretion. Stipop also reserves the right, in its sole discretion, to take steps to verify your identity in connection with your order. You may need to provide additional information to verify your identity before completing your Transaction (such information is included within the definition of Payment Information). Stipop will either not charge you or refund the charges for orders that we do not process or cancel.</p>
                <p><br/><strong style={{fontSize:20}}>Your Stipop Account – Personal Studio</strong></p>
                <p>You may either be a User (create/download stickers and posts) or a Contributor (Or sometimes Artist) (create/download stickers and posts and upload/sell stickers and other related contents) in our Services.</p>
                <p>To become a User, you may sign up in Stipop mobile application. However, for now, most of the features provided in the App is not available on Web. For signing up we only ask for simple information, and they will be handled with care (see ‘Data we Handle’ for more).</p>
                <p>To upload and sell your own sticker sets or other related contents and become a Contributor, you must visit our website and add in additional information and verify email. Once you become a Contributor, you must upload at least 1 set of stickers (with a minimum of 12 stickers in each set) to activate your Contributor Section on your Personal Studio, through which you can manage your stickers and communicate with Users. Becoming a Contributor simply means that a Contributor Section will be added to your original Personal Studio, so you will still be able to download other’s stickers and use the Services like Users.</p>
                <p>To protect your account, keep your password confidential. You are responsible for the activity that happens on or through your account.</p>
                <p><br/><strong style={{fontSize:20}}>Privacy and Copyright Issue</strong></p>
                <p>Stipop’s privacy policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that Stipop can use such data in accordance with our privacy policies.</p>
                <p>As an online service provider, we are entitled to remove any contents that infringe laws regarding copyright, portrait rights or publicity right when the victim or true owner of the content informs us. Again, we state clearly that we will do what is right legally to protect ownership and copyright of contents uploaded to our Services by responding to notices of alleged copyright infringement and terminate accounts of repeated infringers according to the process set out by the law stated in California, United States.</p>
                <p>We provide information to help copyright holders manage their intellectual property online. If you think somebody is violating your copyrights and want to notify us, you can find information about submitting notices and Stipop’s policy about responding to notices in our Help Center.</p>
                <p><br/><strong style={{fontSize:20}}>Your Content in our Services</strong></p>
                <p>Our Services allow you to create and share certain contents.</p>
                <p>Users can leave comments and replies to comments on stickers and posts. You are free to leave any comments if it doesn’t contain any insulting or law-breaking messages. You are free to use the stickers and posts that you download (but did not create) on any messengers of your choice. HOWEVER, this does not mean that you can reproduce or alter the contents of the stickers or re-upload them elsewhere without the owner’s consent. The Ownership of the content belongs to the contributor of whom you have downloaded from, the owner.</p>
                <p>Contributors share same responsibility and rights as the Users but holds ownership of any intellectual property (contents/stickers) he or she uploads. In short, what belongs to you stays yours, even after the upload and sharing with other users through our Services. Therefore, you the owner have exclusive right (as applicable) to reproduce, distribute, publicly display, and make derivative works of your work (sticker) here and elsewhere. Please be reminded that you can upload your stickers elsewhere. All uploaded stickers will be sold to users through in-app purchase and ads, and according payment will be made to Artists. Stickers can be sold by contributors who have met our requirements. It is you and your artworks that will help millions of sticker users around the globe enhance their freedom of expression and ability to express more freely, online. The information you post on your Contributor Page (your portfolio) will be shown to the public for good, and any false information will be asked to be removed.</p>
                <p>Once you agree to the Terms and upload your own stickers, you hereby certify us to upload and share those stickers on our website and mobile applications for the purpose of download and usage in messengers by Stipop users and we may use them for the purpose of marketing and promotion of our Services online and offline without your further approval. We may provide the contents (stickers) to 3rd parties marketing-wise or profit-wise. We will state our usage online, and if asked to remove by the owner from our works we will do so.</p>
                <p>Actions such as choosing to ‘Like’ a sticker, becoming a Contributor’s ‘Fan’ or leaving comments on stickers or Contributor Pages may be revealed to other users with the time of the action taken included. However, when you ‘Unfan’ a Contributor the Contributor won’t be notified personally. Please be noted that all comments you leave and contents you upload can be reported to us if seen as inappropriate by certain users. You can find more information about how Stipop uses and stores content in the privacy policy or other parts of the Terms provided. If you submit feedback or suggestions about our Services, we may use your feedback or suggestions without obligation to you.</p>
                <p><br/><strong style={{fontSize:20}}>Data we Handle</strong></p>
                <p>We are constantly working hard to improve our Services. And for that we may use certain data like user’s usage of Services in numbers (the number of visits to certain pages, the number of likes and downloads of certain stickers etc.), comments and replies to comments made by users, personal information such as age, country you live in and sticker preferences. Please be noticed that none of the information you give to us will be misused, but rather as helpful tools to make our Services better for all of us.</p>
                <p><br/><strong style={{fontSize:20}}>Modifying and Terminating our Services</strong></p>
                <p>We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether.</p>
                <p>You can stop using our Services at any time or delete anything you wrote or uploaded although the stickers already downloaded cannot be deleted by us. Stipop may also stop providing Services to you or add or create new limits to our Services at any time.</p>
                <p>We believe that you own your data and preserving your access to such data is important. If we discontinue a Service, where reasonably possible, we will give you reasonable advance notice and a chance to get information out of that Service.</p>
                <p>We may terminate your access to and use of the Services, at our sole discretion, at any time and without notice to you. You may cancel your Account at any time by sending an email to us at contact@stipop.io. Upon any termination, discontinuation or cancellation of Services or your Account, all provisions of these Terms which by their nature should survive will survive, including, without limitation, ownership provisions, warranty disclaimers, limitations of liability, and dispute resolution provisions.</p>
                <p><br/><strong style={{fontSize:20}}>About these Terms</strong></p>
                <p>We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly. We’ll post notice of modifications to these terms on this page. Changes will not apply retroactively and will become effective no sooner than seven days after they are posted. However, changes addressing new functions for a Service or changes made for legal reasons will be effective immediately. If you do not agree to the modified terms for a Service, you should discontinue your use of that Service. Your continued use of the Service following the posting of any changes to the Terms constitutes Your acceptance of those changes.</p>
                <p>If there is a conflict between these terms and the additional terms, the additional terms will control for that conflict. These terms control the relationship between Stipop and You. They do not create any third-party beneficiary rights. If you do not comply with these terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have (such as taking action in the future). If it turns out that a particular term is not enforceable, this will not affect any other terms. If a conflict rises, law of your country or the law of the country where the service is provided may be applied.</p>
                <p>For information about how to contact Stipop, please visit our Contact page.</p>
            </div>
        );
    }
}

export default Tou;
